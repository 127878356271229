<template>
  <v-card color="grey lighten-3">
    <v-card-title class="title">
      Swap A Team
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="text-center">
      <v-select
        v-if="home|| (user && user.vbl)"
        :items="selectChoices"
        return-object
        v-model="home "
        item-value="id"
        item-text="name"
        label="Home"
        color="color3"
        item-color="color3"
      >
      </v-select>
      <v-select
        v-if="away || (user && user.vbl)"
        :items="selectChoices"
        v-model="away"
        item-text="name"
        item-value="id"
        return-object
        label="Away"
        color="color3"
        item-color="color3"
      ></v-select>
      <v-btn
        v-if="home && away"
        color="success white--text"
        :disabled="!dirty"
        :loading="loading"
        @click.stop="save"
      >Swap 'em</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { firstBy } from 'thenby'
import { mapGetters } from 'vuex'

export default {
  props: ['match', 'pool', 'tournament', 'division'],
  data () {
    return {
      home: null,
      away: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    selectChoices () {
      const result = [...this.getTeams(this.division), ...this.otherDivisionsChoices]

      return result
    },
    otherDivisions () {
      return this.tournament.divisions.filter(f => f.id !== this.division.id)
    },
    otherDivisionsChoices () {
      var a = []
      this.otherDivisions.forEach(d => a.push(...this.getTeams(d)))
      return a
    },
    currentHome () {
      return this.match.homeTeam ? this.selectChoices.find(f => f.id === this.match.homeTeam.teamId) : null
    },
    currentAway () {
      return this.match.awayTeam ? this.selectChoices.find(f => f.id === this.match.awayTeam.teamId) : null
    },
    postUrl () {
      return this.match && this.home && this.away && `/matches/teamSwap?matchId=${this.match.id}&home=${this.home.id}&away=${this.away.id}&pId=${this.match.poolId}&bId=${this.match.bracketId}&n=${this.match.number}`
    },
    dirty () {
      const a = this.home
      const b = this.away
      return a && b && !(a.id === this.currentHome.id && b.id === this.currentAway.id)
    }
  },
  methods: {
    getTeams (d) {
      return d.teams.map(t => {
        return {
          name: t.name,
          id: t.id,
          division: d.name
        }
      }).sort(firstBy('name'))
    },
    selectItem (item) {
      console.log(item)
    },
    save () {
      this.loading = true
      this.$http.post(this.postUrl)
        .then(r => { this.$emit('team-swapped') })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    this.home = this.match.homeTeam ? this.selectChoices.find(f => f.id === this.match.homeTeam.teamId) : null
    this.away = this.match.awayTeam ? this.selectChoices.find(f => f.id === this.match.awayTeam.teamId) : null
  }
}
</script>
